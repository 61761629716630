// ** React Imports
import { Navigate } from "react-router-dom"
import { Suspense } from "react"
import { useApplicationModules } from "@src/hooks/ApplicationModules.hook"
import { useSelector } from "react-redux"

const PrivateRoute = ({ children, route }) => {
  const user = useSelector(state => state.userInfo)
  const { modules } = useApplicationModules()

  if (user) {
    const availableRoutes = ['backoffice/home']
    for (const module of modules) {
      for (const metadata of module.metadata) {
        if (metadata?.metadata === 'navLink') {
          availableRoutes.push(metadata.value)
        }
      }
    }

    if (route) {
      let action = null
      let resource = null
      let restrictedRoute = false

      if (route.meta) {
        action = route.meta.action
        resource = route.meta.resource
        restrictedRoute = route.meta.restricted
      }

      const userHasAccess = availableRoutes.some(available_route => {
        return route.path.includes(available_route)
      })

      if (user && restrictedRoute && !userHasAccess) {
        return <Navigate to="/404" />
      }

      if (!user) {
        return <Navigate to="/" />
      }
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
