import axios from 'axios'
import { API_URL } from '@src/configs/environment'
import { parseQueries, parseQueriesLegacy, queryString, stringQueryByJson } from '../utility/Query'
import { globalHandler } from '@Intelli/utilities'
import moment from 'moment-timezone'

/**
 * @Description Servicio API del backoffice
 */
export default class BackOfficeAPI {
  constructor(baseURL = API_URL) {
    this.API = new axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en',
        timezone: moment.tz.guess(true)
      }
    })
    this.API.interceptors.response.use(
      request => {
        return request
      },
      error => {
        globalHandler(error.response.data)
        return Promise.reject(error)
      }
    )
  }
  /**
   * @Description Envía la invitación al operador con los permisos
   * @param {Object<Invitation>} invitation
   * @returns {Axios response}
   */
  async sendOperatorInvitation(invitation) {
    return await this.API.post('v1/backoffice/invitation', invitation)
  }
  /**
   * Actualiza los permisos del usuario
   * @param {String} user_id
   * @param {Object<Invitation>} data
   * @returns
   */
  async updateOperatorPermissions(user_id, data = {}) {
    return await this.API.put(`v1/backoffice/users/permission/${user_id}`, data)
  }
  /**
   * @Description Reenvía la invitación al operador
   * @param {String} email
   * @returns {Axios response}
   */
  async resendOperatorInvitation(access_id) {
    try {
      const response = await this.API.post(`v1/backoffice/invitation/${access_id}/resend`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async resendAdminInvitation(access_id) {
    try {
      const response = await this.API.post(`v1/backoffice/invitation/admin/${access_id}/resend`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  /**
   * Cambia el status del access
   * @param {String} id
   * @param {Number} status
   * @returns {Axios response}
   */
  async updateUserAccess(data) {
    return await this.API.put(`v1/backoffice/users/${data.id}`, { status: data.status })
  }
  /**
   * Restaura el acceso a nivel de usuario (schema oauth )
   * @param {String} id
   * @param {*} data
   * @returns {Axios response}
   */
  async restoreUserAccess(id, data) {
    return await this.API.put(`v1/backoffice/users/access/${id}`, data)
  }
  /**
   * @Description Obtiene todas las invitaciones del backoffice
   * @param {Number} offset
   * @param {Number} limit Total de registros por página
   * @returns {Axios response}
   */
  async getOperatorInvitations(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/users?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  /**
   * @Description Obtiene la información de perfil de un usuario
   * @param {String} id
   * @returns {Axios response}
   */
  async getUserProfile(id) {
    return this.API.get(`v1/backoffice/profile/${id}`)
  }
  /**
   * @Description Obtiene los documentos ligados a un operador
   * @param {String} id
   * @param {Array<Queries>} queries
   * @returns {Axios response}
   */
  async getOperatorDocuments(id, queries = []) {
    return await this.API.get(`v1/backoffice/profile/${id}/documents?${parseQueries(queries)}`)
  }
  /**
   * @Description Actualiza los datos de perfil de un operator
   * @param {String} id
   * @param {Object<Profile>} data
   * @returns {Axios response}
   */
  async updateOperatorProfile(id, data = {}) {
    return await this.API.put(`v1/backoffice/profile/${id}`, data)
  }
  /**
   * @Description Actualiza la contraseña del usuario operador
   * @param {String} id
   * @param {Object<Credentials>} data
   * @returns {Axios response}
   */
  async updateOperatorPassword(id, data = {}) {
    return await await this.API.put(`v1/backoffice/profile/security/updatePassword/${id}`, data)
  }

  /**
   * @Description Obtains the basic information of the client
   * @param {String} id
   * @returns
   */
  async getClient(id) {
    try {
      const response = await this.API.get(`v1/backoffice/clients/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  /* async getClients(offset = 0, limit = 10, queries = [], statusType, start_date, end_date) {
    return await this.API.get(
      `v1/backoffice/clients?limit=${limit}&offset=${offset}${parseQueriesLegacy(queries)}${
        statusType ? `&state=${statusType}` : ''
      }&start_date__between=${start_date + ' 00:00:00'},${end_date + ' 23:59:59'}`
    )
  } */

  async getClients(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/clients?${stringQueryByJson(query)}`)
      return response
    } catch (e) {
      throw e
    }
  }
  async getClientsInProcess(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/in_process?${stringQueryByJson(query)}`)
      return response
    } catch (e) {
      throw e
    }
  }

  async getClientsInProcessIndex(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/clients/in_process/${query.client_id}?${stringQueryByJson(query)}`)
      return response
    } catch (e) {
      throw e
    }
  }

  async updateClient(data) {
    try {
      const response = await this.API.put(`v1/backoffice/clients/${data.client_id}`, data.data)
      return response.data
    } catch (e) {
      throw e
    }
  }
  /**
   *
   * @param {String} id id del usuario
   * @returns {Axios response}
   */

  async getUserPermissions(id) {
    return await this.API.get(`v1/backoffice/users/permission/${id}`)
  }

  async getModules() {
    try {
      const response = await this.API.get('v1/backoffice/modules')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getOperations() {
    try {
      const response = await this.API.get('v1/backoffice/operations')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getRoles() {
    try {
      const response = await this.API.get('v1/backoffice/roles')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getMenu() {
    try {
      const response = await this.API.get('v1/backoffice/user/modules')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async logout() {
    try {
      const response = await this.API.post('v1/auth/logout')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getUser() {
    try {
      const response = await this.API.get('v1/auth/user')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async updateUser(data) {
    try {
      const response = await this.API.put('v1/auth/user', data.data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getBackofficeUserInfo() {
    try {
      const response = await this.API.get('v1/backoffice/user')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getOperatorProfile(id) {
    try {
      const response = await this.API.get(`v1/backoffice/users/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async updateOperatorInformation(data) {
    try {
      const response = await this.API.put(`v1/backoffice/users/${data.id}`, data.data)
      return response
    } catch (e) {
      throw e
    }
  }

  async restoreUserAccess(data) {
    try {
      const response = await this.API.put(`v1/auth/users/${data.id}`, data.data)
      return response
    } catch (e) {
      throw e
    }
  }

  async getDocument(id) {
    try {
      const response = await this.API.get(`v1/backoffice/users/${id}/documents`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorsDocument(id, query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/${id}/documents?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getDocumentsTypes(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/documents_type?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async postDocument(data) {
    try {
      const response = await this.API.post(`v1/backoffice/contractors/${data.id}/documents`, data.data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractsDetailed(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/${query.id}/contracts?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deleteDocument(data) {
    try {
      const response = await this.API.delete(
        `v1/backoffice/contractors/${data.contractor_id}/documents/${data.document_id}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async send2faCode() {
    try {
      const response = await this.API.post('v1/verification/twoFactor/send')
      return response.data
    } catch (error) {
      throw error
    }
  }

  async validate2faCode(data) {
    try {
      const response = await this.API.post('v1/verification/twoFactor/validate', data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async set2fa(data) {
    try {
      const response = await this.API.post('v1/auth/session/twoFactor', data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async UpdatePassword(data) {
    try {
      const response = await this.API.put('v1/auth/user/password', data.data, {
        headers: { TwoFactorId: data.token }
      })
      return response.data
    } catch (e) {
      throw e
    }
  }

  async SendPhoneCode(data) {
    try {
      const response = await this.API.post('v1/verification/phone/send', data.phone, {
        headers: { TwoFactorId: data.token }
      })
      return response.data
    } catch (e) {
      throw e
    }
  }

  async VerifyPhoneCode(code) {
    try {
      await this.API.post('v1/verification/phone/validate', code.data, {
        headers: { TwoFactorId: code.token }
      })
      return true
    } catch (e) {
      throw e
    }
  }

  async SendEmailCode(data) {
    try {
      return await this.API.post(
        'v1/verification/email/send',
        { email: data.email },
        {
          headers: { TwoFactorId: data.token }
        }
      )
    } catch (e) {
      throw e
    }
  }

  async VerifyEmailCode(code) {
    try {
      await this.API.post('v1/verification/email/validate', code.data, {
        headers: { TwoFactorId: code.token }
      })
      return true
    } catch (e) {
      return false
    }
  }

  //Clients

  //Clients endpoints select

  async getAnsSelect() {
    try {
      const response = await this.API.get('v1/backoffice/ans/search')
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Selects services

  async getServicesSelect() {
    try {
      const response = await this.API.get('v1/backoffice/clients/services')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getPaymentsMethods() {
    try {
      const response = await this.API.get('v1/backoffice/cashout_method')
      return response.data
    } catch (e) {
      throw e
    }
  }
  //StatisticsClients

  //comission:

  async ComissionStatisticsClients() {
    try {
      const response = await this.API.get('v1/backoffice/clients/chart/comission')
      return response.data
    } catch (e) {
      throw e
    }
  }

  //manage:

  async manageStatisticsClients() {
    try {
      const response = await this.API.get('v1/backoffice/clients/chart/manage')
      return response.data
    } catch (e) {
      throw e
    }
  }

  //modalities:

  async modalitiesStatisticsClients() {
    try {
      const response = await this.API.get('v1/backoffice/clients/chart/modalities')
      return response.data
    } catch (e) {
      throw e
    }
  }

  //last three motnhs:

  async threeMonthsStatisticsClients() {
    try {
      const response = await this.API.get('v1/backoffice/clients/chart/last_three_months')
      return response.data
    } catch (e) {
      throw e
    }
  }

  //new clients:

  async newClientsStatistics() {
    try {
      const response = await this.API.get('v1/backoffice/clients/chart/new_clients')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getIndustryType(query = '') {
    try {
      const response = await this.API.get(`v1/backoffice/industry_type/search${query && `?${queryString(query)}`}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async CreateClient(data) {
    try {
      const response = await this.API.post('v1/backoffice/clients', data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async SetClientLogo(data) {
    try {
      const formData = new FormData()
      formData.append('file', data.file)
      const response = await this.API.post(`v1/backoffice/clients/${data.client_id}/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (e) {
      throw e
    }
  }

  async getClientLegalDocuments(id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/clients/${id}/legal_representatives?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async reviewClientLegalDocument(data) {
    try {
      const response = await this.API.put(`v1/backoffice/clients/${data.client_id}/legal_representatives/${data.id}`, {
        descripcion: data.descripcion,
        verified: data.verified
      })
      return response
    } catch (e) {
      throw e
    }
  }

  async EnableDisabledContractor({ id, contractor_id, data }) {
    try {
      const response = await this.API.put(`v1/backoffice/clients/${id}/contractors/${contractor_id}`, data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async updateClientStatus(data) {
    try {
      const response = await this.API.put(`v1/backoffice/clients/${data.client_id}`, data.data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async changeAdminWorkspace(data) {
    try {
      const response = await this.API.post('v1/auth/session/workspace/admin', data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async changeContractorWorkspace(data) {
    try {
      const response = await this.API.post('v1/auth/session/workspace/contractor', data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getTemplates(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/contract_templates?${stringQueryByJson(query)}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getTemplate(id) {
    try {
      const response = await this.API.get(`v1/backoffice/contract_templates/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async createTemplate(data) {
    try {
      const response = await this.API.post(
        'v1/backoffice/contract_templates',
        { ...data.data, file: data.file },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async updateTemplate(data) {
    try {
      const response = await this.API.put(
        `v1/backoffice/contract_templates/${data.id}`,
        { ...data.data, file: data.file },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async deleteTemplate(id) {
    try {
      const response = await this.API.delete(`v1/backoffice/contract_templates/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getContractTypes(query = '') {
    try {
      const response = await this.API.get(`v1/backoffice/contract_type${query && `?query=${query}`}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContracts(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/contractor?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractors(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/contractor?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorInfo(id) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/contractor/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractsByTypeStats() {
    try {
      const response = await this.API.get('v1/backoffice/contractors/chart/for_contract_type')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractsByCountryStats() {
    try {
      const response = await this.API.get('v1/backoffice/contractors/chart/for_country')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getCreatedContractsStats() {
    try {
      const response = await this.API.get('v1/backoffice/contractors/chart/created_contracts')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getActiveContractsStats() {
    try {
      const response = await this.API.get('v1/backoffice/contractors/chart/active_contracts')
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContract(id) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deleteContract(id) {
    try {
      const response = await this.API.delete(`v1/backoffice/contractors/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAdmins(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/admin?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async reviewContract(data) {
    try {
      const response = await this.API.put(`v1/backoffice/contractors/${data.id}`, data.data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async setUserStatus(data) {
    try {
      const response = await this.API.put(`v1/backoffice/contractors/user_status/${data.id}`, data.data)
      return response
    } catch (e) {
      throw e
    }
  }

  //Graphs section

  async createdContracts() {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/created_contracts`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractSignUrl(id) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/${id}/preview_url`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async activeContracts() {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/chart/active_contracts`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAdminInfo(id) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/admin/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async contractorsForCountry() {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/chart/for_country`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async contractorsForPosition() {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/chart/for_position`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async contractorsForContractType() {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/chart/for_contract_type`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Payroll
  async getTransactionsTable(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/payroll/transactions?${stringQueryByJson(query)}&created_at__between=${
          query.start_date + ' 00:00:00'
        },${query.end_date + ' 23:59:59'}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorsTable(id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/payroll/transactions/${id}/payroll?${stringQueryByJson(query)}${
          query.payrollState ? `&dispersion__eq=${query.payrollState}` : ''
        }`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async confirmPay(data, transaction_id) {
     try {
      const response = await this.API.post(
        `v1/backoffice/payroll/transactions/${transaction_id}/confirm`,
        {...data},
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (e) {
      throw e
    } 
  }

  async returnPay(data) {
    try {
      const response = await this.API.post(
        `v1/backoffice/payroll/transactions/${data.transaction_id}/return`,
        { file: data.data.file, reference_number: data.data.reference_number },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async rejectTransfer(data, transaction_id) {
    /* console.log({data})
    console.log({ transaction_id }) */
    try {
      const response = await this.API.post(`v1/backoffice/payroll/transactions/${transaction_id}/reject`, {
        ...data
       // description: data.reason,
        //client_id: data.client_id 
      },
    )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getActiveContractors(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/payroll/contractors?${stringQueryByJson(query)}${
          query.dispersionState ? `&dispersion__eq=${query.dispersionState}` : ''
        }`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async doDispersion(data) {
    try {
      const response = await this.API.post(
        `v1/backoffice/payroll/transactions/${data.transaction_id}/payroll/${data.transaction_payroll_id}/dispersion`,
        {
          file: data.data.file,
          /* reference_number: data.data.reference_number, */
          user_cashout_id: data.data.user_cashout_id
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async cancelDispersion(transaction_payroll_id) {
    try {
      const response = await this.API.delete(`v1/backoffice/transactions_payroll/${transaction_payroll_id}/dispersion`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async cashoutFavorite(contractor_id) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/${contractor_id}/cashout/favorite`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async cashoutMethod(data) {
    try {
      const response = await this.API.get(`v1/backoffice/contractors/${data}/cashout`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async cashoutMethodSearch(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/contractors/${query.contractor_id}/cashout?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getCashoutPayment(data) {
    try {
      const response = await this.API.get(
        `v1/backoffice/payroll/transactions/${data.transaction_id}/payroll/${data.transaction_payroll_id}/details/${data.cashout_method_id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async payrollDetail(transaction_id) {
    try {
      const response = await this.API.get(`v1/backoffice/payroll/transactions/${transaction_id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Charts payroll
  async payrollChartByState() {
    try {
      const response = await this.API.get(`v1/backoffice/payroll/transactions/chart/total_by_state`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async payrollChartTotalToApprove() {
    try {
      const response = await this.API.get(`v1/backoffice/payroll/transactions/chart/total_to_approve`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async payrollChartTotalToPaid() {
    try {
      const response = await this.API.get(`v1/backoffice/payroll/transactions/chart/total_paid`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorPayroll(contractor_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/contractors/${contractor_id}/payroll?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editProfilePic(data) {
    try {
      const formData = new FormData()
      formData.append('file', data)
      const response = await this.API.post(`v1/auth/user/photo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (e) {
      throw e
    }
  }

  async accountingCurrent(query = {}) {
    try {
      const response = await this.API.get(`v1/accounting/current?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async currentMonthTotalGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/current/chart/total`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async currentMonthTotalPayrollGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/current/chart/payroll`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async currentMonthTotalSAASGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/current/chart/saas`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async currentMonthTotalComissionGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/current/chart/commission`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async currentMonthTotalServicesGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/current/chart/services`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async accountingToBeInvoiced(query = {}) {
    try {
      const response = await this.API.get(`v1/accounting/invoicing?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async ToBeInvoicedTotalGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/invoicing/chart/total`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async ToBeInvoicedTotalSAASGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/invoicing/chart/saas`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async ToBeInvoicedTotalCommissionGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/invoicing/chart/commission`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async ToBeInvoicedTotalServicesGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/invoicing/chart/services`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async ToBeInvoicedTotalPayrollGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/invoicing/chart/payroll`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async accountingHistoric(query = {}) {
    try {
      const response = await this.API.get(`v1/accounting/historic?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async historicTotalGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/historic/chart/total`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async historicTotalSAASGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/historic/chart/saas`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async historicTotalServicesGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/historic/chart/services`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async historicTotalCommissionGraphs() {
    try {
      const response = await this.API.get(`v1/accounting/historic/chart/commission`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async monthlyDetailIndex(client_id) {
    try {
      const response = await this.API.get(`v1/accounting/current/${client_id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async monthlyDetailTable(query = {}) {
    try {
      const response = await this.API.get(
        `v1/accounting/current/${query.client_id}/details?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async toBeInvoicedIndex(invoice_id) {
    try {
      const response = await this.API.get(`v1/accounting/invoice/${invoice_id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async toBeInvoicedTable(query = {}) {
    try {
      const response = await this.API.get(
        `v1/accounting/invoice/${query.invoice_id}/details?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async uploadInvoice(data) {
    try {
      const response = await this.API.put(
        `v1/accounting/invoice/${data.invoice_id}/upload_doc`,
        { file: data.data.file, number: data.data.number },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response
    } catch (e) {
      throw e
    }
  }

  async historicIndex(invoice_id) {
    try {
      const response = await this.API.get(`v1/accounting/invoice/${invoice_id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async historicTable(query = {}) {
    try {
      const response = await this.API.get(
        `v1/accounting/invoice/${query.invoice_id}/details?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async invoicedChart(client_id) {
    try {
      const response = await this.API.get(`v1/accounting/current/chart/${client_id}/invoiced`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async clientServices(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/clients/${query.client_id}/services?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getServicesType(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/services_type/search?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async addServices(data) {
    try {
      const response = await this.API.post(`v1/backoffice/services`, { services: data.services })
      return response
    } catch (e) {
      throw e
    }
  }

  async editService(data) {
    try {
      const response = await this.API.put(`v1/backoffice/services/${data.service_id}`, data.services)
    } catch (e) {
      throw e
    }
  }

  async cancelServices(data) {
    try {
      const response = await this.API.post(`v1/backoffice/services/${data.service_id}/voided`)
      return response
    } catch (e) {
      throw e
    }
  }

  async getServiceIndex(service_id) {
    try {
      const response = await this.API.get(`v1/backoffice/services/${service_id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }
  async confirmPayServices(data) {
    try {
      const response = await this.API.post(
        `v1/backoffice/services/${data.services_id}/success`,
        { file: data.data.file, reference_number: data.data.reference_number },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response
    } catch (e) {
      throw e
    }
  }

  async notifyPaymentService(data) {
    try {
      const response = await this.API.post(`v1/backoffice/clients/${data.client_id}/services/notify_payment`)
      return response
    } catch (e) {
      throw e
    }
  }

  async notifyPaymentOneService(data) {
    try {
      const response = await this.API.post(`v1/backoffice/services/${data.service_id}/notify_payment`)
      return response
    } catch (e) {
      throw e
    }
  }

  async getApplications(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/productivity/applications?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAppsClassification() {
    try {
      const response = await this.API.get(`v1/backoffice/productivity/applications/chart/class`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAppsMostUsed() {
    try {
      const response = await this.API.get(`v1/backoffice/productivity/applications/chart/apps`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAppsCategories() {
    try {
      const response = await this.API.get(`v1/backoffice/productivity/applications/chart/category`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getApplication(id) {
    try {
      const response = await this.API.get(`v1/backoffice/productivity/applications/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async createApplicationCategory(data) {
    try {
      const response = await this.API.post(`v1/backoffice/productivity/category`, data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getApplicationCategories(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/category/search?${stringQueryByJson(query)}&order=name`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getApplicationHierarchies(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/applications/search/class?${stringQueryByJson(query)}&order=value`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getApplicationBehaviors(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/applications/search/behavior?${stringQueryByJson(query)}&order=value`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getApplicationTypes(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/applications/search/type?${stringQueryByJson(query)}&order=value`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editApplicationIcon(data) {
    try {
      const formData = new FormData()
      formData.append('file', data.file)
      const response = await this.API.put(`v1/backoffice/productivity/applications/${data.id}/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (e) {
      throw e
    }
  }

  async deleteApplicationIcon(data) {
    try {
      const response = await this.API.delete(`v1/backoffice/productivity/applications/${data.id}/logo`)
      return response
    } catch (e) {
      throw e
    }
  }

  async editApplication(data) {
    try {
      const response = await this.API.put(`v1/backoffice/productivity/applications/${data.id}`, data.data)
      return response
    } catch (e) {
      throw e
    }
  }

  async getParentApplications(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/applications/search/parents?${stringQueryByJson(query)}&order=name`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAssignedApplications(id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/applications/${id}/children?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getUnassignedApplications(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/applications/unassigned?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async assignApplications(data) {
    try {
      const response = await this.API.post(
        `v1/backoffice/productivity/applications/${data.parent_id}/children/${data.children_id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async unassignApplications(data) {
    try {
      const response = await this.API.delete(
        `v1/backoffice/productivity/applications/${data.parent_id}/children/${data.children_id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivityProfiles(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/productivity/profile?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async createProductivityProfile() {
    try {
      const response = await this.API.post(`v1/backoffice/productivity/profile`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async updateProductivityProfile(data) {
    try {
      const response = await this.API.put(`v1/backoffice/productivity/profile/${data.id}`, data.data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deleteProductivityProfile(id) {
    try {
      const response = await this.API.delete(`v1/backoffice/productivity/profile/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivityProfile(id) {
    try {
      const response = await this.API.get(`v1/backoffice/productivity/profile/${id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivitySearch(type, query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/profile/search/${type}?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivityApplications(id, type, query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/productivity/profile/${id}/apps/${type}?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async updateApplicationProductivity(data) {
    try {
      const response = await this.API.put(`v1/backoffice/productivity/profile/${data.id}/app`, data.data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getCashoutMethodsCommission(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/parameters/cashout_methods?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async updateCashoutMethodsCommission(data) {
    try {
      const response = await this.API.put(`v1/backoffice/parameters/cashout_methods`, data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getCurrentChart(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/chart/current?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getStatusChart(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/chart/status?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivityChart(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/chart/productivity?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAppsChart(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/chart/apps?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivityDashboard(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/dashboard?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getApps(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/applications?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivityTimeLine(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/timeline?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAllAppsSession(data, query = {}) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/contractors/${data.contractor_id}/timeline/${data.session_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorProductivityCharts(contractor_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/chart/contractors/${contractor_id}/productivity?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAppsChartProductivity(contractor_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/chart/contractors/${contractor_id}/apps?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivityDetailsDashboard(contractor_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/contractors/${contractor_id}/details?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorApps(id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/contractors/${id}/applications?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getMostUsedAppsPerType(contractor_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/chart/contractors/${contractor_id}/apps?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTimelineDetails(query = {}, contractor_id) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/contractors/${contractor_id}/timeline?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getClientHours(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/time?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorHours(query = {}, contractor_id) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/contractors/${contractor_id}/time?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getHoursStats(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/time/chart/stats?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorHoursStats(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/time/chart/stats?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTimeExtra(query = {}) {
    try {
      const response = await this.API.get(
        `v1/productivity/backoffice/time/chart/extra_time?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorWeeklyWorkedHours(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/time/chart/week?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getWeeklyWorkedHours(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/time/chart/week?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProductivityCharts(query = {}) {
    try {
      const response = await this.API.get(`v1/productivity/backoffice/chart/times?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorsLogs(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/apps/logs?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAppHistory(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/apps/urls?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async uploadApplication(data) {
    try {
      const response = await this.API.put(
        `v1/backoffice/apps/urls/${data.app_url_id}`,
        {
          file: data.file,
          ...data.data
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getOsSearch(query = {}) {
    try {
      const response = await this.API.get(`v1/backoffice/apps/urls/search/os?${stringQueryByJson(query)}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getWebLog(contractor_id) {
    try {
      const response = await this.API.get(`v1/backoffice/apps/logs/front_errors/${contractor_id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getAppLog(contractor_id) {
    try {
      const response = await this.API.post('v1/backoffice/apps/logs/request/', {
        contractor_id
      })
      return response.data
    } catch (e) {
      throw e
    }
  }
}
