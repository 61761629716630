import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

const BreadcrumbsBar = ({ className }) => {
  const { path = [] } = useApplicationModules()
  const { t } = useTranslation()
  const { id } = useParams()
  const { pathname } = useLocation()
  const breadcrumbs = useSelector(state => state.breadcrumbs)
  const routes = useSelector(state => state.routes)

  const bc = []
  const noShowCrum = ['profile', 'detail']
  let curr_path = pathname

  while (path.length > 0 && curr_path !== path[0].navLink) {
    const route = routes.find(r => r === curr_path || r === `${curr_path.substring(0, curr_path.lastIndexOf('/'))}/:id`)

    if (route) {
      if (!!route.name || path.map(p => p.navLink).includes(route)) {
        bc.push(path.find(p => p.navLink === route))
      } else {
        if (!noShowCrum.includes(route.substring(0, curr_path.lastIndexOf('/')).split('/').pop())) {
          bc.push({
            name: `breadcrumbs.${route.replace('/:id', '').split('/').pop()}`,
            navLink: route.includes('id') ? '#' : route
          })
        }
        /* bc.push({
          name: `breadcrumbs.${route.replace('/:id', '').split('/').pop()}`,
          navLink: route.includes('id') ? '#' : route
        }) */
      }
    }
    curr_path = curr_path.substring(0, curr_path.lastIndexOf('/'))
  }

  bc.reverse()
  bc.unshift(path[0])

  const target = (() => {
    const is_nested = path.some(available_route => {
      return pathname.includes(available_route.navLink)
    })

    if (id) {
      return breadcrumbs[id]
    } else if (breadcrumbs[pathname.replace('/backoffice', '')]) {
      return breadcrumbs[pathname.replace('/backoffice', '')]
    } else if (is_nested) {
      return breadcrumbs[`/${pathname.split('/').pop()}`]
    }
  })()

  return (
    <div className={`content-header row align-content-center ps-1 ${className}`}>
      <div className="content-header-left ps-0 ">
        <div className="row breadcrumbs-top ">
          <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12 p-0 ">
            <Breadcrumb className="ps-0">
              {bc?.map((item, index) => {
                function isUpperCase(text) {
                  return text === text.toUpperCase()
                }
                const Tag = item?.last ? 'span' : Link
                const classBread = !!item && `text-white ${isUpperCase(item?.name) && 'fw-bolder fs-4'}`
                return (
                  <BreadcrumbItem key={index}>
                    <Tag className={classBread} to={item?.navLink || '#'}>
                      {t(item?.name, {
                        ns: 'modules'
                      })}
                    </Tag>
                  </BreadcrumbItem>
                )
              })}
              {typeof target === 'object'
                ? target?.map((targ, index) => (
                    <BreadcrumbItem key={index}>
                      <Link className="text-white" to={targ?.navLink || '#'}>
                        {targ.last ? targ.name : t(`breadcrumbs.${targ.name}`, { ns: 'modules' })}
                      </Link>
                    </BreadcrumbItem>
                  ))
                : target && (
                    <BreadcrumbItem>
                      <span className="text-white">{t(target, { ns: 'modules' })}</span>
                    </BreadcrumbItem>
                  )}
            </Breadcrumb>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BreadcrumbsBar
