// ** Dropdowns Imports
import CustomUserDropdown from "./CustomUserDropdown"
import { IntlDropdown } from "@Intelli/utilities"
import CustomNotificationDropdown from "./CustomNotificationDropdown"
// import SettingsButton from "./SettingsButton"

const CustomNavbarUser = () => {
  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      {/* <IntlDropdown /> */}
      {/* <CustomNotificationDropdown /> */}
      {/* <SettingsButton /> */}
      <CustomUserDropdown />
    </ul>
  )
}
export default CustomNavbarUser