import { JpAvatar, JpCountryFlag, JpText } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { getDataSet, reduce } from 'iso3166-2-db'
import i18n from 'i18next'



const MainInfoHeader = ({ data, column = '' }) => {
  const listOfCountries = reduce(getDataSet(), i18n.language)
  const { t } = useTranslation()
  return (
    <Row>
      <Col lg="2" className="mx-2 mb-1 text-center" >
        {data.logo ? (
          <JpAvatar
            className="avatar-cover rounded bg-white"
            imgClassName="rounded dashboard_img border px-2 cursor-default"
            img={data.logo}
            imgHeight={150}
            imgWidth={180}
            color="white"
          />
        ) : (
          <div className=" border p-1 d-flex justify-content-center cursor-default">
            <JpAvatar
              className="rounded"
              imgClassName="rounded-0 cursor-default"
              content={data.name}
              initials
              color="primary"
              size="xxxl"
            />
          </div>
        )}
      </Col>

      <Col  className="ms-1">
        <Row className="mb-50">
          <Col lg='3'>
            <b className="fw-bolder text-primary">{t('Name')}:</b>
          </Col>
          <Col>
            <JpText>{data.name}</JpText>
          </Col>
        </Row>

        <Row className="mb-50">
          <Col lg='3'>
            <b className="fw-bolder text-primary">{t('COUNTRY')}:</b>
          </Col>
          <Col>
            <JpCountryFlag countryCode={data?.country} />

            <span className="ms-75">{listOfCountries[data?.country]?.name || ''}</span>
          </Col>
        </Row>
        <Row className="mb-50">
          <Col lg='3'>

            <b className="fw-bolder text-primary">{t('ADDRESS')}:</b>
          </Col>
          <Col>

            <JpText>{(data?.first_line || '') + (data?.second_line || '')}</JpText>
          </Col>
        </Row>
        <Row className="mb-50">
          <Col lg='3'>

            <b className="fw-bolder text-primary">{t('EMAIL')}:</b>
          </Col>
          <Col>

            <JpText>{data?.email || '-'}</JpText>
          </Col>
        </Row>
        <Row className="mb-50">
          <Col lg='3'>

            <b className="fw-bolder text-primary">{t('PHONE')}:</b>
          </Col>
          <Col>

            <JpText>{data?.phone || '-'}</JpText>
          </Col>
        </Row>
      </Col>

      {column && column}
    </Row>
  )
}

export default MainInfoHeader