import { Row, Col, Card, CardHeader, CardBody, Form, Button, InputGroup, InputGroupText, Input } from 'reactstrap'
import { JpButton, JpLabel } from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import JpCurrencyInputController from '@src/components/input/JpCurrencyInputController'
import JpInputController from '@src/components/input/JpInputController'
import JpAsyncSelectController from '@src/components/input/JpAsyncSelectController'
import JpTextAreaController from '@src/components/input/JpTextAreaController'
import BackOfficeAPI from '@src/services/API'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const RepeatForm = ({ modalAdd, modalEdit, client_id, service_id, title, defaultValues }) => {
  const { t } = useTranslation()
  const API = new BackOfficeAPI()
  const navigate = useNavigate()

  const SignupSchema = yup.object().shape({
    repeater: yup.array().of(
      yup.object().shape({
        value: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : value))
          .min(1, 'form.AMOUNT.min')
          .max(99999999, 'form.AMOUNT.max')
          .required('form.AMOUNT.required'),
        count: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : value))
          .min(1, 'form.AMOUNT.min')
          .max(99999999, 'form.AMOUNT.max')
          .required('form.AMOUNT.required'),
        description: yup.string().max(200, 'form.clients.MESSAGE.max').required('form.DESCRIPTION.required'),
        service_type_id: yup.object().required('InputRequired').nullable()
      })
    )
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(SignupSchema)
  })


  const { fields, append, remove } = useFieldArray({
    control,
    name: 'repeater'
  })

  //Services type Select
  const { data: getServicesType, isLoading: getServicesTypeIsLoading } = useQuery(['getServicesType'], () =>
    API.getServicesType()
  )

  const loadServices = async value => {
    return await API.getServicesType({
      query: value
    }).then(res =>
      res.data.map(i => ({
        value: i.id,
        label: i.name
      }))
    )
  }

  const onSubmit = data => {
    const services = data?.repeater?.map(i => {
      let dataSend = {
        count: Number(i.count),
        value: Number(i.value),
        description: i.description,
        service_type_id: i.service_type_id.value,
        client_id : client_id
      }
      return dataSend
    })
    let editSend = {
        count: Number(data?.repeater[0]?.count),
        value: Number(data?.repeater[0]?.value),
        description: data?.repeater[0]?.description,
        service_type_id: data?.repeater[0]?.service_type_id?.value
      }
    if (!service_id) {
      modalAdd(services)
    } else {
      modalEdit(service_id, editSend)
    }
  }
  return (
    <Card>
      <CardHeader>
        <h4 className="card-title">{title}</h4>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, i) => {
            let amt = watch(`repeater.${i}.count`)
            let unitVal = watch(`repeater.${i}.value`)
            return (
              <div key={field.id}>
                <Row>
                  <Col md={4}>
                    <JpAsyncSelectController
                      name={`repeater.${i}.service_type_id`}
                      control={control}
                      title={`${t(`views.clients.SERVICES`)}*`}
                      errors={
                        errors?.repeater && { [`repeater.${i}.service_type_id`]: errors?.repeater[i]?.service_type_id }
                      }
                      optionsAsyncSelect={{
                        defaultOptions: getServicesType?.data?.map(item => ({
                          value: item.id,
                          label: item.name
                        }))
                      }}
                      loadOptions={loadServices}
                    />
                  </Col>
                  <Col md={2}>
                    <JpInputController
                      name={`repeater.${i}.count`}
                      control={control}
                      title={`${t(`views.services.count`)}*`}
                      ns="errors"
                      errors={errors?.repeater && { [`repeater.${i}.count`]: errors?.repeater[i]?.count }}
                      optionsInput={{
                        type: 'number',
                        placeholder: '1'
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <JpCurrencyInputController
                      name={`repeater.${i}.value`}
                      control={control}
                      title={`${t(`views.accounting.unitValue`)}*`}
                      errors={errors?.repeater && { [`repeater.${i}.value`]: errors?.repeater[i]?.value }}
                      optionsInput={{
                        placeholder: '100'
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <JpLabel>{t(`views.accounting.totalValue`)}</JpLabel>
                    <InputGroup>
                      <InputGroupText>{'US$'}</InputGroupText>
                      <Input
                        name={t(`views.accounting.totalValue`)}
                        type="number"
                        disabled
                        addon
                        value={(unitVal && amt) !== undefined ? parseFloat(unitVal || 0, 1) * amt : 0}
                      />
                    </InputGroup>
                  </Col>

                  <Col md={12} className="mt-md-75">
                    <JpTextAreaController
                      name={`repeater.${i}.description`}
                      control={control}
                      errors={errors?.repeater && { [`repeater.${i}.description`]: errors?.repeater[i]?.description }}
                      title={`${t(`views.services.description`)}*`}
                      optionsInput={{
                        placeholder: `${t('views.services.descriptionPlaceholder')}`
                      }}
                    />
                  </Col>

                  {i !== 0 && (
                    <Col md={2}>
                      <JpButton
                        className="me-75 text-nowrap px-1"
                        type="cancel"
                        text={t(`views.services.deleteService`)}
                        options={{
                          onClick: () => {
                            remove(i)
                          }
                        }}
                      />
                    </Col>
                  )}

                  <Col sm={12}>
                    <hr />
                  </Col>
                </Row>
              </div>
            )
          })}
          <div className={`${!service_id ? 'd-flex justify-content-between' : 'd-flex justify-content-end'}`}>
            {!service_id && (
              <Button type="button" className="btn-icon" color="info" onClick={() => append({})}>
                <FontAwesomeIcon icon={faPlus} />
                <span className="align-middle ms-25">{t(`views.services.addOther`)}</span>
              </Button>
            )}

            <div className="d-flex">
              <JpButton
                className="me-75"
                type="cancel"
                text={t('modals.cancel')}
                options={{
                  onClick: () => {
                    navigate(-1)
                  }
                }}
              />

              <Button color="green" type="submit" className="btn-icon">
                <span className="align-middle p-1">{t('save')}</span>
              </Button>
            </div>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default RepeatForm
