import { JpLabel } from '@Intelli/utilities'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFeedback, Input } from 'reactstrap'

const JpInputController = ({
  name,
  title,
  control,
  errors = {},
  optionsController = {},
  optionsInput = {},
  ns = 'global'
}) => {
  const { t } = useTranslation()
  return (
    <>
      {title && <JpLabel labelOptions={{ for: name }}>{title}</JpLabel>}
      <Controller
        name={name}
        control={control}
        {...optionsController}
        render={({ field }) => <Input id={name} invalid={!!errors[name]} {...field} {...optionsInput} />}
      />
      <FormFeedback invalid>{t(errors[name]?.message, { ns })}</FormFeedback>
    </>
  )
}

export default JpInputController
