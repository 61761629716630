import { createSlice } from "@reduxjs/toolkit"


export const settingsSlice =  createSlice(
    {
        name: 'settings',
        initialState: {
            email:''
        },
        reducers:{
            setUsrEmail: (state, action) => {
                if (action.type === 'settings/setUsrEmail') {
                    state.email = action.payload
                }
            }
        }
    }
)

export const {setUsrEmail} = settingsSlice.actions

export default settingsSlice.reducer