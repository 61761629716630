import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { handleAddApplicationModules } from '@src/redux/navbar'
import { useEffect, useMemo } from 'react'

/**
 * It takes a pathname and a list of modules and returns a list of modules that are in the pathname
 * @param [pathname] - The pathname of the current page.
 * @param [modules] - The modules array that you get from the API.
 * @returns the path of the current page.
 */
function pathRecursiveSearch(pathname = '', modules = []) {
  let path = []
  for (const module of modules) {
    const targetLink = module?.metadata?.find(row => row.metadata === 'navLink')

    if (pathname?.indexOf(targetLink?.value) !== -1) {
      path.push({
        name: module.name,
        order: module.order,
        navLink: targetLink.value
      })
      if (module?.children?.length > 0) {
        path = [...path, ...pathRecursiveSearch(pathname, module?.children)]
      } else {
        break
      }
    }
  }
  return path
}

export function useApplicationModules() {
  const dispatch = useDispatch()
  const modules = useSelector(state => state.navbar.modules || []) // Array of modules
  const location = useLocation()
  const path = useMemo(() => {
    return pathRecursiveSearch(location.pathname, modules)
  }, [location.pathname, modules]) // Array of modules for render bread bar
  function setApplicationsModules(modules = []) {
    dispatch(handleAddApplicationModules(modules))
  }

  useEffect(() => {
    // TODO add uuid params filter
  }, [location])

  return {
    modules,
    path,
    setApplicationsModules
  }
}
