// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
// import navigation from "@src/navigation/vertical"

import CustomNavbar from '../components/navbar/CustomNavbar'

// ** Third Party Components
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import BackOfficeAPI from '../services/API'
import { useQuery } from '@tanstack/react-query'

// Hooks
import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'
import { HOME_PATH } from '@src/configs/environment'
import { Col, Row } from 'reactstrap'
import logo from "@src/assets/images/logo/logo_white_horizontal.svg"

library.add(fas, far)
const CustomFooter = () => {
  // ** Hooks
  const { t } = useTranslation()
  return (
    <Row className="pt-50 pb-50">
        <img className='pe-1' style={{maxWidth: '135px'}} src={logo} alt="logo" />
        <Col>
          <div>
            <small className="footer-text">
            {t('Footer-TextOne')}{' '}{new Date().getFullYear()}{t('Footer-TextTwo')}
            </small>
          </div>
          <div>
            {/* <small className="footer-text">
            {t('Footer-underText')}
            </small> */}
          </div>
        </Col>
      </Row>
  )
}

const VerticalLayout = props => {
  const API = new BackOfficeAPI()
  const { t } = useTranslation()
  const [menu, setMenu] = useState([])
  //** For ServerSide navigation
  const { data: menuData, isError: menuHasError, isLoading: menuIsLoading } = useQuery(['menu'], () => API.getMenu())
  const { setApplicationsModules } = useApplicationModules()
  useEffect(() => {
    if (!menuIsLoading && !menuHasError) {
      const navigation = []
      /* navigation.push({
        id: "home",
        title: 'HOME',
        order: 0,
        icon: <FontAwesomeIcon icon={faHome} />,
        navLink: HOME_PATH
      }) */
      for (const module of menuData?.data?.filter(i => !i.hidden)) {
        const moduleMetadata = {}
        for (const metadata of module.metadata) {
          moduleMetadata[metadata.metadata] = metadata.value
        }
        navigation.push({
          id: module.name,
          title:module.name,
          order: module.order,
          icon: moduleMetadata?.icon ? (
            <FontAwesomeIcon icon={moduleMetadata?.icon} />
          ) : (
            <FontAwesomeIcon icon={faHome} />
          ),
          navLink: moduleMetadata?.navLink ? `${moduleMetadata?.navLink}` : HOME_PATH
        })
      }
      
      setApplicationsModules(menuData.data)
      setMenu(navigation.sort((a, b) => a.order - b.order))
    }
  }, [menuData, menuHasError, menuIsLoading])

  return (
    <Layout menuData={menu} navbar={props => <CustomNavbar {...props} />} footer={<CustomFooter />} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
