import { Row, Col, Card, CardHeader, CardBody, Form, Button, InputGroup, InputGroupText, Input } from 'reactstrap'
import { SweetModal, JpButton, JpLabel, SpinnerLoader } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import JpCurrencyInputController from '@src/components/input/JpCurrencyInputController'
import JpInputController from '@src/components/input/JpInputController'
import JpAsyncSelectController from '@src/components/input/JpAsyncSelectController'
import JpTextAreaController from '@src/components/input/JpTextAreaController'
import { useNavigate, useParams } from 'react-router-dom'
import BackOfficeAPI from '@src/services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import RepeatForm from '@src/components/services/RepeatForm'

const AddServices = () => {
  const { t } = useTranslation()
  const API = new BackOfficeAPI()

  const navigate = useNavigate()

  const { client_id } = useParams()

  const { mutate: notifyPaymentService, isLoading: notifyPaymentServiceIsLoading } = useMutation(
    data => API.notifyPaymentService(data)
  )

  // Add services
  const { mutate: addServices, isLoading: addServicesIsLoading } = useMutation(data => API.addServices(data), {
    onSuccess: () => {
      notifyPaymentService({client_id})
      SweetModal('success', t('SUCCESS'), t('views.services.successfulCreated'), t('Ok')), navigate(-1)
    }
  })

  const modalAdd = (services) =>
    SweetModal(
      'warning',
      t('views.services.newServices'),
      t('views.services.modalQuestionServices'),
      t('Ok'),
      result => {
        if (result.isConfirmed) {
          addServices({
            services
          })
        }
      },
      { showCancelButton: true, cancelButtonText: t('CANCEL') }
    )

  if (addServicesIsLoading || notifyPaymentServiceIsLoading) {
    return <SpinnerLoader />
  }

  let dfValues = { repeater: [{ service_type_id: undefined }] }

  return (
    <RepeatForm
      modalAdd={modalAdd}
      defaultValues={dfValues}
      client_id={client_id}
      title={t('views.services.addServices')}
    />
  )
}

export default AddServices
