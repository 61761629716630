import { createSlice } from '@reduxjs/toolkit'

export const user = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    setUser: (state, action) => {
      for (const key in action.payload) {
        state[key] = action.payload[key]
      }
    }
  }
})

export const { setUser } = user.actions

export default user.reducer
